<template>
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">

            <div class="row mt-5">
                <div class="col-12 ">
                    <h2 class="text-primary">Dashboard</h2>
                </div>

                <div class="row g-2 mb-4">
                    <div class="col-12">
                        <div class="alert alert-success" role="alert">
                            Selamat Datang di Web Brayan
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">

                    </div>
                </div>
                <!--//row-->
                <div class="row mb-1">

                </div>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Dashboard',
    data() {
        return {
            //state loggedIn with localStorage
            loggedIn: localStorage.getItem('loggedIn'),
            //state token
            token: localStorage.getItem('token'),
            kantorcabangid: '',
            //state user logged In
            user: [],

            search: '',
            page: 1,
            cabang: [],
            tanggal1: [],
            bulan1: [],
            tahun1: [],
            tanggal2: [],
            bulan2: [],
            tahun2: [],
            karyawan: [],
            invoice: '',
            show: [],
            jumtransaksi: 0,
            jumpendapatan: 0,
            cabang1: '',
            cabang2: '',
            cabang3: '',
            pilihIndex1: '',
            pilihIndex2: '',
            dataInv: [],
            chartOptions: {},
            series: [],
            chartOptions2: {},
            series2: [],
            chartOptions3: {},
            series3: [],
            nominal: null,
            link: this.globalApiUrl,
            loading: true,
            loadingbuka: true,
            tokobuka: false,
            tokoidbuka: '',
        }
    },
    created() {
        // this.$forceUpdate();
        // this.loading = true;
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                return this.$router.push({
                    name: 'login.index'
                })
            }
        } else {

            axios.get(this.link + 'api/user', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.user = response.data.data.user
                this.loading = false;
                this.loadingbuka = false;
            })

        }

    },
    methods: {

        logout() {
            axios.get(this.link + 'api/logout')
                .then(() => {
                    //remove localStorage
                    localStorage.removeItem("loggedIn")
                    localStorage.removeItem("token")

                    //redirect
                    return this.$router.push({
                        name: 'login'
                    })
                })
        },

    },

}
</script>

<style>
.colordashboard {
    color: #747987;
}

.shadowcard {
    box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
    -webkit-box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
    -moz-box-shadow: 0px 3px 5px 1px rgba(163, 161, 161, 0.48);
}
</style>
